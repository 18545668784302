<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">庆贺！我所曾建晟主任当选厦门市司法鉴定协会监事长</div>
            <p class="time">2021-11-04</p>
            <p class="space_content" >10月29日，厦门市司法鉴定协会第五届会员代表大会在市司法局召开。大会选举产生了新一届理事会成员和新一届领导班子，其中，福建历思司法鉴定所主任曾建晟当选为协会监事长。</p>
            <div class="col8" style="margin:2rem auto">
                <img class="col8" v-lazy="require('../../assets/news/ls/2-1.png')">
                <p class="center_">曾建晟作为新一届厦门司法鉴定协会监事长发表讲话</p>
            </div>
            <p class="space_content">厦门市司法鉴定协会是由厦门市司法鉴定机构和司法鉴定人及从事司法鉴定工作的相关人员组成的具有法人资格的、地方性、专业性、非营利性的社会组织。</p>
            
        </div>
        <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p><router-link class="a" :to="'/news/lsnews/1'"><p class="ellipsis1">历思战略合作单位——青岛中院领导莅临福建历思司法鉴定所调研指导！</p></router-link>
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>
                    <router-link class="a" :to="'/news/lsnews/3'"><p class="ellipsis1">省创新研究院中科育成中心领导莅临历思调研</p></router-link>
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/news/lsnews'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>